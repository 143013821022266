<template>
  <div>
    <div id="data-list-list-view" class="data-list-container">
      <vs-table ref="table" :max-items="itemsPerPage" :data="users" :key="componentKey" noDataText="Añade una nueva regla de bloqueo">

        <div slot="header" class="flex flex-wrap items-center flex-grow justify-between">

          <div class="flex flex-wrap-reverse items-center">
            <!-- ADD NEW -->
            <b-button class="btn-campaign-filter mb-5" variant="outline-secondary" @click="addrow = true" id="addRule">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" /> Añadir regla
            </b-button>
          </div>
        </div>

        <template slot="thead">
          <vs-th class="rule-name"></vs-th>
          <vs-th sort-key="limit" class="rule-limit">Límite</vs-th>
          <vs-th class="rule-type"></vs-th>
          <vs-th sort-key="time" class="rule-time">Tiempo</vs-th>
          <vs-th sort-key="active" class="rule-active">Activar / Eliminar</vs-th>
        </template>

        <template slot-scope="{data}">
          <tbody>

            <vs-tr v-show="addrow==true" id="newrow" state="primary">
              <!-- Añadir cambio en permitir clicks/ bloquear por key -->
              <vs-td class="rule-name">
                <span class="rule-txt">{{textini}}</span>
              </vs-td>
              <vs-td class="rule-name p-0">
                <div class="rule-txt posnumber">
                  <b-form-input :type="'number'" min="1" value="1" v-model="limitClick"></b-form-input>
                </div>
              </vs-td>
              <vs-td class="rule-name">
                <span class="rule-txt"><span v-if="typeofdata[0].name=='clicks'">clic</span> en</span>
              </vs-td>
              <vs-td class="rule-name">
                <div class="input-group rule-txt">
                  <div class="posnumber">
                    <b-form-input :type="'number'" min="1" value="1" v-model="timeNum"></b-form-input>
                  </div>
                  <div class="postime">
                    <vs-select value="minutes" v-model="timeNumUnit">
                      <vs-select-item :key="newindex" :value="timeitem.type" :text="timeitem.name" v-for="(timeitem,newindex) in timeType" />
                    </vs-select>
                  </div>
                </div>
              </vs-td>
              <vs-td>
                <feather-icon icon="CheckIcon" class="text-success iconsrow cursor-pointer" v-b-popover.hover.top="'Añadir regla'" @click="addNewRule"></feather-icon>
                <feather-icon icon="XIcon" class="text-danger iconsrow ml-5 cursor-pointer" v-b-popover.hover.top="'Cancelar'" @click="addrow=false"></feather-icon>
              </vs-td>
            </vs-tr>

            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

              <vs-td class="rule-name" :data="textini">
                <p class="rule-txt">{{textini}}</p>
              </vs-td>

              <vs-td class="rule-name" :data="tr.limit">
                <p class="rule-txt">{{ tr.limit }}</p>
              </vs-td>

              <vs-td class="rule-name" :data="tr.type">
                <span class="rule-txt"><span v-if="tr.type == 'click'">clic</span> en</span>
              </vs-td>

              <vs-td class="rule-name" :data="tr.time && tr.timeUnit">
                <p class="rule-txt">{{ tr.time }} <span v-if="tr.timeUnit=='years'">año</span><span v-else-if="tr.timeUnit=='hours'">horas</span><span v-else-if="tr.timeUnit=='minutes'">minutos</span><span v-else>días</span></p>
              </vs-td>
              <vs-td class="rule-name">
                <div class="inrow">
                  <feather-icon icon="Trash2Icon" class="text-danger iconsrow ml-5 cursor-pointer" v-b-popover.hover.top="'Eliminar'" @click="deleteElementModal(tr.id)"></feather-icon>
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>
    <b-modal id="deleteElement" ref="deleteElement" :visible="deleteElement" centered>
      <template v-slot:modal-title>
        <img src="../assets/images/logo/clickdefense-logo-small-dark.png" width="40%">
      </template>
      <h3 class="text-center font-weight-bold">¡Atención!</h3>
      <p class="my-4 text-center">
        Estás a punto de eliminar una regla de negocio.
      </p>
      <p class="my-4 text-center">
        ¿deseas eliminar la regla?
      </p>
      <template v-slot:modal-footer>
        <b-button @click="confirmDeleteElement" size="sm" variant="outline-light" class="startButton">
          <span class="text-decoration-none text-white font-weight-bold">Aceptar</span>
        </b-button>
        <b-button @click="cancelDeleteElement" size="sm" variant="outline-light" class="cancelButton">
          <span class="text-decoration-none text-white font-weight-bold">Cancelar</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import Amplify from 'aws-amplify'
import utils from '@/api/utils.js'

export default {
  data() {
    return {
      id: '',
      limitClick: 1,
      timeNum: 1,
      timeNumUnit: 'minutes',
      addrow: false,
      textini: 'Permitir hasta',
      typeofdata: [{ name: 'clicks' }, { name: 'keyword' }],
      timeType: [{ type: 'minutes', name: 'minutos' }, { type: 'hours', name: 'horas' }, { type: 'days', name: 'días' }, { type: 'years', name: 'año' }],
      users: [],
      itemsPerPage: 4,
      isMounted: false,
      addNewDataSidebar: false,
      componentKey: 0,
      deletedId: "",
      deleteElement: false,
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
  },
  methods: {
    async initialGetUserData(dataUserId) {
      await this.$initMainData(dataUserId)
      this.readUserRules()
      this.isMounted = true
    },
    confirmDeleteElement() {
      const id = this.deletedId
      this.$store.dispatch('removeUserRule', id)
      Amplify.Auth.currentSession().then(apiSession => {
        this.$axios.delete(`/userrules/${id}`, {
          headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
        }).then(response => {
          if (response.status === 200) {
            this.cancelDeleteElement()
            this.users = []
            this.readUserRules()
            this.componentKey += 1
            this.$gtm.push({ event: 'Regla_Eliminada', userId: this.$store.state.user.id })
          }
        })
        // FIN Get user
      })
      this.readUserRules()
    },
    cancelDeleteElement() {
      this.deletedId = ""
      this.deleteElement = false
      this.$refs['deleteElement'].hide()
    },
    deleteElementModal(id) {
      this.deletedId = id
      this.deleteElement = true
      this.$refs['deleteElement'].show()
    },
    addNewRule() {
      // TODO: validar formulario
      this.$vs.loading()
      Amplify.Auth.currentSession().then(apiSession => {
        // INI Create user rule
        const paramsUserRul = {
          name: this.textini,
          limit: this.limitClick,
          type: 'click',
          time: this.timeNum,
          timeUnit: this.timeNumUnit,
          delAfter: 1000,
          delAfterUnit: 'months',
          active: true,
          ruleType: 'add',
          accountId: this.$store.state.user.mainAccount
        }
        if (this.id !== '') {
          paramsUserRul.id = this.id
        } else {
          paramsUserRul.id = this.$uuid.v4()
        }
        this.$axios.post('/userrules/0', paramsUserRul, {
          headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
        }).then(response => {
          if (response.status === 200) {
            this.$store.dispatch('updateUserRule', paramsUserRul)
            this.readUserRules()
            this.componentKey += 1
            this.addrow = false
            this.$vs.loading.close()
            this.$gtm.push({ event: 'Nueva_Regla_Añadida', userId: this.$store.state.user.id })
          }
        })
        // FIN Create user rule
      })
    },
    formatData(data) {
      // formats data received from API
      let formattedData = data.map((item) => {
        let obj = {
          name: item.name,
          limit: item.limit,
          type: item.type,
          timeFormat: `${item.time} ${item.timeUnit}`,
          delAfterFormat: `${item.delAfter} ${item.delAfterUnit}`,
          id: item.id,
          time: item.time,
          timeUnit: item.timeUnit,
          delAfter: item.delAfter,
          delAfterUnit: item.delAfterUnit
        }
        obj['active'] = true
        return obj
      });
      return formattedData
    },
    readUserRules() {
      const dataUR = this.$store.state.userRuleList
      if (dataUR.length > 0) {
        this.users = this.formatData(dataUR)
      }
    },
  },
  mounted() {
    EventBus.$on('user-rules-updated', () => {
      this.selected = []
      this.readUserRules()
      this.componentKey += 1
    })
    Amplify.Auth.currentSession().then(apiSession => {
      const paramsCheckAdmin = apiSession.accessToken.payload['cognito:groups']
      this.$store.dispatch('updateAdmin', utils.isAdminUser(paramsCheckAdmin))
      if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount !== '') {
        this.initialGetUserData(this.$store.state.user.id)
        this.$acl.change('admin')
      } else if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount === '') {
        this.$vs.loading.close()
        this.$acl.change('admin')
        this.$router.push('/admin-page')
      } else {
        this.initialGetUserData('0')
        this.$vs.loading.close()
      }
    })
    EventBus.$on('change-account-rules', (accountId) => {
      let userRuleList = []
      let userId = ''
      if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount !== '') {
        userId = this.$store.state.user.id
      } else if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount === '') {
        userId = this.$store.state.user.id
      } else {
        userId = '0'
      }

      this.$vs.loading()
      Amplify.Auth.currentSession().then(apiSession => {
        // INI Get Rules
        this.$axios.get(`/userrules/0/${accountId}/${userId}`, {
          headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
        }).then(response => {
          for (const [keyUserRul, valueUserRul] of Object.entries(response.data)) {
            const userRule = {
              active: valueUserRul.active,
              delAfter: valueUserRul.delAfter,
              delAfterUnit: valueUserRul.delAfterUnit,
              id: valueUserRul.id,
              limit: valueUserRul.limit,
              name: valueUserRul.name,
              time: valueUserRul.time,
              timeUnit: valueUserRul.timeUnit,
              type: valueUserRul.type,
              key: keyUserRul
            }
            userRuleList.push(userRule)
          }
          this.$store.dispatch('updateUserRules', userRuleList)
          this.users = []
          this.selected = []
          this.readUserRules()
          this.componentKey += 1
          this.$vs.loading.close()
        }).catch(() => {
          this.$vs.loading.close()
        })
        // FIN Get Rules
      })
    })
  }
}
</script>

<style lang="scss">
.rule-name {
  width: 20%;
}
.rule-limit {
  width: 20%;
}
.rule-type {
  width: 20%;
}
.rule-time {
  width: 20%;
}
.rule-active {
  width: 20%;
}

.rule-txt {
  font-size: 1.2rem;
}
.iconsrow {
  display: unset;
}
#newrow > .td-check {
  opacity: 0;
}
.posnumber {
  width: 34%;
  z-index: 10;
  margin: 5px auto;
}
.posnumber > input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.postime {
  width: 60%;
}
.postime > div {
  width: 85%;
}
.inrow {
  display: flex;
}
.td-edit {
  text-decoration: none;
}
.data-list-container {
  padding-top: 15px;
}
#data-list-list-view {
  .vs-con-table {
    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
#deleteElement___BV_modal_footer_ {
  justify-content: center !important;
  background-color: #fff;
  color: gray;
}
#deleteElement___BV_modal_header_ {
  background-color: #2f1b7a;
  color: white;
}
#deleteElement___BV_modal_header_ > button {
  color: white;
}
#deleteElement___BV_modal_body_ {
  background-color: #fff;
  color: gray;
}
#deleteElement___BV_modal_title_ {
  color: gray;
}
#deleteElement___BV_modal_outer_ {
  z-index: 55000 !important;
}
.startButton {
  background-color: #2f1b7a;
}
.startButton:hover {
  background-color: #6556d7;
}
.startButton:hover a {
  color: #212529 !important;
}
.cancelButton {
  background-color: #bb0303;
}
.cancelButton:hover {
  background-color: #fa3a3a;
  color: black;
}
.cancelButton:hover a {
  color: #292121 !important;
}
</style>