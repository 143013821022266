var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "data-list-container",
          attrs: { id: "data-list-list-view" }
        },
        [
          _c(
            "vs-table",
            {
              key: _vm.componentKey,
              ref: "table",
              attrs: {
                "max-items": _vm.itemsPerPage,
                data: _vm.users,
                noDataText: "Añade una nueva regla de bloqueo"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var data = ref.data
                    return [
                      _c(
                        "tbody",
                        [
                          _c(
                            "vs-tr",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.addrow == true,
                                  expression: "addrow==true"
                                }
                              ],
                              attrs: { id: "newrow", state: "primary" }
                            },
                            [
                              _c("vs-td", { staticClass: "rule-name" }, [
                                _c("span", { staticClass: "rule-txt" }, [
                                  _vm._v(_vm._s(_vm.textini))
                                ])
                              ]),
                              _c("vs-td", { staticClass: "rule-name p-0" }, [
                                _c(
                                  "div",
                                  { staticClass: "rule-txt posnumber" },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        type: "number",
                                        min: "1",
                                        value: "1"
                                      },
                                      model: {
                                        value: _vm.limitClick,
                                        callback: function($$v) {
                                          _vm.limitClick = $$v
                                        },
                                        expression: "limitClick"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _c("vs-td", { staticClass: "rule-name" }, [
                                _c("span", { staticClass: "rule-txt" }, [
                                  _vm.typeofdata[0].name == "clicks"
                                    ? _c("span", [_vm._v("clic")])
                                    : _vm._e(),
                                  _vm._v(" en")
                                ])
                              ]),
                              _c("vs-td", { staticClass: "rule-name" }, [
                                _c(
                                  "div",
                                  { staticClass: "input-group rule-txt" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "posnumber" },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "number",
                                            min: "1",
                                            value: "1"
                                          },
                                          model: {
                                            value: _vm.timeNum,
                                            callback: function($$v) {
                                              _vm.timeNum = $$v
                                            },
                                            expression: "timeNum"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "postime" },
                                      [
                                        _c(
                                          "vs-select",
                                          {
                                            attrs: { value: "minutes" },
                                            model: {
                                              value: _vm.timeNumUnit,
                                              callback: function($$v) {
                                                _vm.timeNumUnit = $$v
                                              },
                                              expression: "timeNumUnit"
                                            }
                                          },
                                          _vm._l(_vm.timeType, function(
                                            timeitem,
                                            newindex
                                          ) {
                                            return _c("vs-select-item", {
                                              key: newindex,
                                              attrs: {
                                                value: timeitem.type,
                                                text: timeitem.name
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "vs-td",
                                [
                                  _c("feather-icon", {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top",
                                        value: "Añadir regla",
                                        expression: "'Añadir regla'",
                                        modifiers: { hover: true, top: true }
                                      }
                                    ],
                                    staticClass:
                                      "text-success iconsrow cursor-pointer",
                                    attrs: { icon: "CheckIcon" },
                                    on: { click: _vm.addNewRule }
                                  }),
                                  _c("feather-icon", {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top",
                                        value: "Cancelar",
                                        expression: "'Cancelar'",
                                        modifiers: { hover: true, top: true }
                                      }
                                    ],
                                    staticClass:
                                      "text-danger iconsrow ml-5 cursor-pointer",
                                    attrs: { icon: "XIcon" },
                                    on: {
                                      click: function($event) {
                                        _vm.addrow = false
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._l(data, function(tr, indextr) {
                            return _c(
                              "vs-tr",
                              { key: indextr, attrs: { data: tr } },
                              [
                                _c(
                                  "vs-td",
                                  {
                                    staticClass: "rule-name",
                                    attrs: { data: _vm.textini }
                                  },
                                  [
                                    _c("p", { staticClass: "rule-txt" }, [
                                      _vm._v(_vm._s(_vm.textini))
                                    ])
                                  ]
                                ),
                                _c(
                                  "vs-td",
                                  {
                                    staticClass: "rule-name",
                                    attrs: { data: tr.limit }
                                  },
                                  [
                                    _c("p", { staticClass: "rule-txt" }, [
                                      _vm._v(_vm._s(tr.limit))
                                    ])
                                  ]
                                ),
                                _c(
                                  "vs-td",
                                  {
                                    staticClass: "rule-name",
                                    attrs: { data: tr.type }
                                  },
                                  [
                                    _c("span", { staticClass: "rule-txt" }, [
                                      tr.type == "click"
                                        ? _c("span", [_vm._v("clic")])
                                        : _vm._e(),
                                      _vm._v(" en")
                                    ])
                                  ]
                                ),
                                _c(
                                  "vs-td",
                                  {
                                    staticClass: "rule-name",
                                    attrs: { data: tr.time && tr.timeUnit }
                                  },
                                  [
                                    _c("p", { staticClass: "rule-txt" }, [
                                      _vm._v(_vm._s(tr.time) + " "),
                                      tr.timeUnit == "years"
                                        ? _c("span", [_vm._v("año")])
                                        : tr.timeUnit == "hours"
                                        ? _c("span", [_vm._v("horas")])
                                        : tr.timeUnit == "minutes"
                                        ? _c("span", [_vm._v("minutos")])
                                        : _c("span", [_vm._v("días")])
                                    ])
                                  ]
                                ),
                                _c("vs-td", { staticClass: "rule-name" }, [
                                  _c(
                                    "div",
                                    { staticClass: "inrow" },
                                    [
                                      _c("feather-icon", {
                                        directives: [
                                          {
                                            name: "b-popover",
                                            rawName: "v-b-popover.hover.top",
                                            value: "Eliminar",
                                            expression: "'Eliminar'",
                                            modifiers: {
                                              hover: true,
                                              top: true
                                            }
                                          }
                                        ],
                                        staticClass:
                                          "text-danger iconsrow ml-5 cursor-pointer",
                                        attrs: { icon: "Trash2Icon" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteElementModal(tr.id)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-wrap items-center flex-grow justify-between",
                  attrs: { slot: "header" },
                  slot: "header"
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap-reverse items-center" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-campaign-filter mb-5",
                          attrs: {
                            variant: "outline-secondary",
                            id: "addRule"
                          },
                          on: {
                            click: function($event) {
                              _vm.addrow = true
                            }
                          }
                        },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "PlusIcon", svgClasses: "h-4 w-4" }
                          }),
                          _vm._v(" Añadir regla\n          ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", { staticClass: "rule-name" }),
                  _c(
                    "vs-th",
                    {
                      staticClass: "rule-limit",
                      attrs: { "sort-key": "limit" }
                    },
                    [_vm._v("Límite")]
                  ),
                  _c("vs-th", { staticClass: "rule-type" }),
                  _c(
                    "vs-th",
                    { staticClass: "rule-time", attrs: { "sort-key": "time" } },
                    [_vm._v("Tiempo")]
                  ),
                  _c(
                    "vs-th",
                    {
                      staticClass: "rule-active",
                      attrs: { "sort-key": "active" }
                    },
                    [_vm._v("Activar / Eliminar")]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "deleteElement",
          attrs: {
            id: "deleteElement",
            visible: _vm.deleteElement,
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [
                  _c("img", {
                    attrs: {
                      src: require("../assets/images/logo/clickdefense-logo-small-dark.png"),
                      width: "40%"
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "startButton",
                      attrs: { size: "sm", variant: "outline-light" },
                      on: { click: _vm.confirmDeleteElement }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-decoration-none text-white font-weight-bold"
                        },
                        [_vm._v("Aceptar")]
                      )
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "cancelButton",
                      attrs: { size: "sm", variant: "outline-light" },
                      on: { click: _vm.cancelDeleteElement }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-decoration-none text-white font-weight-bold"
                        },
                        [_vm._v("Cancelar")]
                      )
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("h3", { staticClass: "text-center font-weight-bold" }, [
            _vm._v("¡Atención!")
          ]),
          _c("p", { staticClass: "my-4 text-center" }, [
            _vm._v(
              "\n      Estás a punto de eliminar una regla de negocio.\n    "
            )
          ]),
          _c("p", { staticClass: "my-4 text-center" }, [
            _vm._v("\n      ¿deseas eliminar la regla?\n    ")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }